export const Colors = [
    '#d44848',
    '#489be3',
    '#6b6fff',
    '#fad643',
    '#a000ff',
    '#c47d01',
    '#9ef01a',
    '#38b000',
    '#007200',
    '#ff7f51',
    '#fca266',
    '#fcc066',
    '#ff9500',
    '#ffaa00',
    '#dbb42c',
    '#c9a227',
    '#b69121',
    '#0c9aff',
    '#005eb0',
]
